import { faker } from '@faker-js/faker';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  query,
  where,
  getDocs,
  updateDoc,
  getDoc
} from "firebase/firestore";
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Box,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Collapse,
  Grid,
  Select,
  InputLabel,
  Divider,
  CardContent
} from '@mui/material';
// Dialog
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';

// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';

import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

// sections
import { UserListHead, UserListToolbar, AddExchangeForm } from '../sections/@dashboard/user';
import { auth, db, storage } from "../firebase";
import { useAuth } from "../context/AuthContext";
import { id } from 'date-fns/locale';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'isVerified', label: 'Verified', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

let EXCHANGELIST;

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ExchangesPage() {

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [exchangeList, setExchangeList] = useState([]);

  const [objectPage, setObjectPage] = useState(false)
  const [edit, setEdit] = useState(false)

  const [selectedExchange, setSelectedExchange] = useState(null)
  const [exchangeId, setExchangeId] = useState(null);
  const [exchanges, setExchanges] = useState([]);

  const [apikey, setApikey] = useState(null);
  const [apisecret, setApisecret] = useState(null);
  const [clientid, setClientid] = useState(null);
  const [password, setPassword] = useState(null);
  const [totp, setTotp] = useState(null);

  const { signup, currentUser } = useAuth();

  const getList = async () => {
    const docRef = doc(db, 'Customers', currentUser.uid);
    const docSnap = await getDoc(docRef);
    const list = []
    if (docSnap.exists()) {
      // console.log("Document data:", docSnap.data());
      const aExchanges = docSnap.data().exchanges;
      setExchanges(aExchanges);
      aExchanges.forEach((exchange, index) => {
        const sAvatarUrl = exchange.exchangeName === "Binance" ? `/assets/images/avatars_exchanges/binance.svg` : exchange.exchangeName === "Bybit" ? `/assets/images/avatars_exchanges/bybit.svg` : exchange.exchangeName === "Angelone" ? `/assets/images/avatars_exchanges/angelone.png` : ``;
        const oItem = {
          avatarUrl: sAvatarUrl,
          name: exchange.name,
          isVerified: "true",
          status: "active",
          id: exchange.apikey,
          exchange: exchange,
          index: index
        }
        list.push(oItem);
      });

    }
    setExchangeList(list)
  };

  useEffect(() => {
    const oDoc = getList();
  }, []);

  EXCHANGELIST = exchangeList;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = EXCHANGELIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleShowMoreDetails = async (event, id, exchange, index) => {
    setObjectPage(true)
    setExchangeId(index)
    setSelectedExchange(exchange)
    setApikey(exchange.apikey)
    setApisecret(exchange.apisecret)
    if (exchange.exchangeName === "Angelone") {
      setClientid(exchange.clientid)
      setPassword(exchange.password)
      setTotp(exchange.totp)
    }
  };

  const handleBackToList = () => {
    setObjectPage(false)
  };

  const handleEdit = async (event) => {
    setEdit(true)
  };

  const handleSave = async (event) => {
    try {
      // First, update the selectedExchange object
      let updatedSelectedExchange = {}
      if (selectedExchange.exchangeName === "Angelone") {
        updatedSelectedExchange = {
          ...selectedExchange, // Spread the current state
          apikey,
          apisecret,
          clientid,
          password,
          totp,
        };

      } else {
        updatedSelectedExchange = {
          ...selectedExchange, // Spread the current state
          apikey,
          apisecret
        };

      }

      setSelectedExchange(updatedSelectedExchange);

      // Update the exchangeList with the new selectedExchange data
      const updatedExchangeList = exchangeList.map((exchange, i) => {
        if (i === exchangeId) {
          return {
            ...exchange,
            id: apikey,              // Update the id to the new apikey
            exchange: updatedSelectedExchange, // Use the updated selectedExchange
          };
        }
        return exchange;
      });

      setExchangeList(updatedExchangeList);

      // Now, update the exchanges array with the updated selectedExchange
      const updatedExchanges = exchanges.map((exchange, i) => {
        if (i === exchangeId) {
          return updatedSelectedExchange;
        }
        return exchange; // Return the unchanged objects
      });

      setExchanges(updatedExchanges);

      // Update Firestore with the newly updated exchanges array
      const DocRef = doc(db, 'Customers', currentUser.uid);
      await updateDoc(DocRef, {
        exchanges: updatedExchanges, // Use the updated state directly
      });
      console.log('Exchange/Broker updated successfully!');
      setEdit(false);
      alert("Exchange/Broker updated");
    } catch (error) {
      console.error('Error updating Exchange/Broker: ', error);
      alert('Error updating Exchange/Broker');
    }
  };

  const handleCancel = (event) => {
    setApikey(selectedExchange.apikey)
    setApisecret(selectedExchange.apisecret)
    setClientid(selectedExchange.clientid)
    setPassword(selectedExchange.password)
    setTotp(selectedExchange.totp)
    setEdit(false)
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - EXCHANGELIST.length) : 0;

  const filteredUsers = applySortFilter(EXCHANGELIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Exchanges | BuildMyStock </title>
      </Helmet>
      <Container>
        <Box
          sx={{
            height: '50px',
            backgroundColor: 'rgb(30, 136, 229)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start', // Aligns the text to the left
            paddingLeft: '16px', // Adds some padding to the left for spacing
            borderRadius: '8px', // Optional: curved edges
            color: 'white', // Text color
            mb: 5
          }}
        >
          <Typography variant="h6">
            Exchanges/Brokers
          </Typography>
        </Box>
        {!objectPage &&
          <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={5}>
            <Button variant="contained" component={Link} to="/dashboard/addexchange" startIcon={<Iconify icon="eva:plus-fill" />}>
              Add
            </Button>
          </Stack>
        }
        {!objectPage &&
          <Card>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={EXCHANGELIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { id, name, role, status, company, avatarUrl, isVerified, index, exchange } = row;
                      const selectedUser = selected.indexOf(name) !== -1;

                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                          <TableCell padding="checkbox">
                            <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                          </TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={name} src={avatarUrl} />
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="left">{isVerified ? 'Yes' : 'No'}</TableCell>

                          <TableCell align="left">
                            <Label color={(status === 'banned' && 'error') || 'success'}>{sentenceCase(status)}</Label>
                          </TableCell>

                          <TableCell align="right">
                            <IconButton size="large" color="inherit" onClick={() => handleShowMoreDetails(event, id, exchange, index)}>
                              <Iconify icon={'uiw:d-arrow-right'} />
                            </IconButton>
                          </TableCell>

                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={EXCHANGELIST.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        }
        {objectPage && selectedExchange && <Card>

          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
              variant="contained"
              onClick={() => handleBackToList()}
              startIcon={<Iconify icon="eva:arrow-left-fill" />}
            >
              Back
            </Button>
          </Box>

          <Collapse key={selectedExchange.apikey} in={selectedExchange.apikey !== null} timeout="auto" unmountOnExit>
            <Paper variant="outlined" square style={{ padding: '10px', margin: '10px' }}>
              <Stack direction="row" alignItems="center" justifyContent="flex-end">
                <Box>
                  <Button variant="contained" onClick={() => handleEdit()} color="primary" aria-label="edit" startIcon={<EditIcon />}>
                    Edit
                  </Button>
                </Box>
                <Box sx={{ ml: 1 }}>
                  <Button variant="contained" onClick={() => handleCancel()} color="primary" aria-label="edit" startIcon={<Iconify icon="mdi:cancel-bold" />}>
                    Cancel
                  </Button>
                </Box>
              </Stack>
              <Typography variant="h6" component="div" sx={{ mt: 2 }}>
                {selectedExchange.name}
              </Typography>
              <Divider sx={{ mb: 1 }} />
              <Grid container spacing={2} sx={{ m: 2 }} alignItems="center" justifyContent="left">
                <Grid item xs={6} sm={3} md={3} sx={{ml:2}}>
                  {/* <Card
                    sx={{
                      display: selectedExchange.exchangeName === "" ? "none" : "inherit",
                      padding: '16px',
                      textAlign: 'center', // Centers the avatar
                      boxShadow: 3, // Optional: Adds shadow to the card
                      backgroundColor: '#f0f8ff', // Light background color (Alice Blue)
                      borderRadius: '8px', // Optional: Adds rounded corners
                    }}
                  >
                    <CardContent>
                      <Avatar
                        alt="exchange icon"
                        src={
                          selectedExchange.exchangeName === "Binance"
                            ? `/assets/images/avatars_exchanges/binance.svg`
                            : selectedExchange.exchangeName === "Bybit"
                              ? `/assets/images/avatars_exchanges/bybit.svg`
                              : selectedExchange.exchangeName === "Angelone"
                                ? `/assets/images/avatars_exchanges/angelone.png`
                                : ``
                        }
                        sx={{
                          // width: 56,
                          // height: 56,
                          width: '100%',
                          height: '100%',
                          margin: 'auto', // Centers the avatar horizontally
                        }}
                      />
                    </CardContent>
                  </Card> */}
                  <Avatar
                    alt="exchange icon"
                    src={
                      selectedExchange.exchangeName === "Binance"
                        ? `/assets/images/avatars_exchanges/binance.svg`
                        : selectedExchange.exchangeName === "Bybit"
                          ? `/assets/images/avatars_exchanges/bybit.svg`
                          : selectedExchange.exchangeName === "Angelone"
                            ? `/assets/images/avatars_exchanges/angelone.png`
                            : ``
                    }
                    sx={{
                      // width: 56,
                      // height: 56,
                      width: '40%',
                      height: '40%',
                      // margin: 'auto', 
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography variant="body2" color="text.secondary">
                    Exchange/Broker : {selectedExchange.exchangeName}
                  </Typography>
                </Grid>
                {/* <Grid item xs={6}>
                  <Avatar
                    alt="exchange icon"
                    src={(
                      selectedExchange.exchangeName === "Binance" ? `/assets/images/avatars_exchanges/binance.svg` : selectedExchange.exchangeName === "Bybit" ?
                        `/assets/images/avatars_exchanges/bybit.svg` : selectedExchange.exchangeName === "Angelone" ? `/assets/images/avatars_exchanges/angelone.png` : ``
                    )}
                    sx={{
                      width: 56, height: 56,
                      display: selectedExchange.exchangeName === "" ? "none" : "inherit"
                    }}
                  />
                </Grid> */}




              </Grid>
              {!edit &&
                <>
                  <Typography variant="h6" component="div">
                    Credentials
                  </Typography>
                  <Divider sx={{ mb: 1 }} />
                  <Grid container spacing={2} sx={{ m: 2 }}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography variant="body2" color="text.secondary">
                        API Key : {selectedExchange.apikey}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography variant="body2" color="text.secondary">
                        API Secret : {selectedExchange.apisecret}
                      </Typography>
                    </Grid>
                    {selectedExchange.exchangeName === "Angelone" && <>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography variant="body2" color="text.secondary">
                          clientid : {selectedExchange.clientid}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography variant="body2" color="text.secondary">
                          password : {selectedExchange.password}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography variant="body2" color="text.secondary">
                          totp : {selectedExchange.totp}
                        </Typography>
                      </Grid>
                    </>}
                  </Grid>
                </>}
              {edit &&
                <>
                  <Typography variant="h6" component="div">
                    Credentials
                  </Typography>
                  <Divider sx={{ mb: 3 }} />
                  <Grid container spacing={2} sx={{ m: 2 }}>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        label="API Key"
                        type="text"
                        variant="outlined"
                        value={apikey}
                        size="small"
                        margin="normal"
                        onChange={(e) => setApikey(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        label="API Secret"
                        type="text"
                        variant="outlined"
                        value={apisecret}
                        size="small"
                        margin="normal"
                        onChange={(e) => setApisecret(e.target.value)}
                      />
                    </Grid>
                    {selectedExchange.exchangeName === "Angelone" && <>
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          label="Client Id"
                          type="text"
                          variant="outlined"
                          value={clientid}
                          size="small"
                          margin="normal"
                          onChange={(e) => setClientid(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          label="Password"
                          type="text"
                          variant="outlined"
                          value={password}
                          size="small"
                          margin="normal"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          label="TOTP"
                          type="text"
                          variant="outlined"
                          value={totp}
                          size="small"
                          margin="normal"
                          onChange={(e) => setTotp(e.target.value)}
                        />
                      </Grid>
                    </>}
                    <Grid item xs={12} sx={{ mr: 7 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button variant="contained" onClick={() => handleSave()} color="primary" aria-label="save" startIcon={<Iconify icon="material-symbols:save" />}>
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </>}
            </Paper>
          </Collapse>
        </Card>}
      </Container >
    </>
  );
}
