import { faker } from '@faker-js/faker';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  query,
  where,
  getDocs,
  updateDoc,
  getDoc,
  deleteDoc,
  FieldPath,
  documentId
} from "firebase/firestore";

import { getDatabase, ref, set, onValue, update, child, push } from "firebase/database";
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Box,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Collapse,
  Grid,
  Select,
  InputLabel
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
// Dialog
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';

import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
// sections
import { UserListHead, UserListToolbar, AddExchangeForm } from '../sections/@dashboard/user';
// mock
// import USERLIST from '../_mock/user';
import { auth, db, rdb, storage } from "../firebase";
import { useAuth } from "../context/AuthContext";
import { GifBoxSharp, Margin } from '@mui/icons-material';
// ----------------------------------------------------------------------

//Fields - statName,tradeCategory,transaction, optionType, Market (NIFTY50, crypto coins),exchange, rules,logs,tradeEngines

const TABLE_HEAD = [
  { id: 'name', label: 'Strategy', alignRight: false },
  { id: 'type', label: 'Type', alignRight: false },
  { id: 'market', label: 'Market', alignRight: false },
  { id: '' },
];

let USERLIST;

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function MyAlgosPage() {

  //Algo related

  const [algoName, setAlgoName] = useState(null);
  const [algoType, setAlgoType] = useState(null);
  const [market, setMarket] = useState(false)
  const [additional, setAdditional] = useState("")
  const [rules, setRules] = useState(false)
  //end


  const [open, setOpen] = useState(null);
  const [statId, setStatId] = useState(null);
  const [objectPage, setObjectPage] = useState(false)
  const [selectedAlgo, setSelectedAlgo] = useState(null)
  const [edit, setEdit] = useState(false)
  const [copied, setCopied] = useState(false)
  const [algoActive, setAlgoActive] = useState(null)
  const [algos, setAlgos] = useState(null)
  const [uAlgos, setUAlgos] = useState(null)

  const [ltp, setLtp] = useState(null);
  const [lot, setLot] = useState(null);
  const [tkp1, setTkp1] = useState(null);
  const [tkp, setTkp] = useState(null);
  const [sl, setSl] = useState(null);
  const [tl, setTl] = useState(null);
  const [paperTrade, setPaperTrade] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [openDialog, setOpenDialog] = useState(false);

  const [exchangeList, setExchangeList] = useState([]);
  const [loading, setLoading] = useState(false);

  const { signup, currentUser } = useAuth();

  const getList = async () => {
    const docRef = doc(db, 'Customers', currentUser.uid);
    const docSnap = await getDoc(docRef);
    const list = []
    if (docSnap.exists()) {
      // console.log("Document data:", docSnap.data());
      const aStrategies = docSnap.data().strategies;
      const aStrategiesIds = aStrategies.map((strategy) => strategy.id)
      setUAlgos(aStrategies)
      const q = query(collection(db, 'Strategies'), where(documentId(), 'in', aStrategiesIds));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // orders.push({ id: doc.id, ...doc.data() });
        const strategy = doc.data()
        const sAvatarUrl = `/assets/images/avatars_exchanges/statergy.svg`;
        const oItem = {
          // avatarUrl: sAvatarUrl,
          // name: strategy,
          name: strategy.statName,
          type: strategy.tradeCategory,
          market: strategy.market,
          id: doc.id,
        }
        list.push(oItem);
      });

      // aStrategies.forEach((strategy) => {
      //   const sAvatarUrl = `/assets/images/avatars_exchanges/statergy.svg`;
      //   const oItem = {
      //     avatarUrl: sAvatarUrl,
      //     // name: strategy,
      //     name: strategy.name,
      //     id: strategy.id,
      //     exchange: "Angelone",
      //     // isVerified: "true",
      //     isVerified: "http://up.BuildMyStock.com/angelone/trade/" + strategy.id,
      //     status: "active"
      //   }
      //   list.push(oItem);
      // });

    }
    setAlgos(list)
    setExchangeList(list)
  };

  useEffect(() => {
    const oDoc = getList();
  }, []);

  console.log(exchangeList);
  USERLIST = exchangeList;

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // const handleOpenMenu = (event) => {
  //   setOpen(event.currentTarget);
  // };

  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setStatId(id)
  };
  const handleShowMoreDetails = async (event, id) => {
    setObjectPage(true)
    if (id) {
      setStatId(id)
      const docRef = doc(db, 'Strategies', id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data());
        let oStrategy = docSnap.data()
        let sLogs = ""
        if (oStrategy.market === "CRYPTO") {
          for (let i = 0; i < oStrategy.logs.length; i++) {
            let s1 = oStrategy.logs[i].S1 && oStrategy.logs[i].S1.support ? oStrategy.logs[i].S1.support : ""
            let s2 = oStrategy.logs[i].S2 && oStrategy.logs[i].S2.support ? oStrategy.logs[i].S2.support : ""
            let s3 = oStrategy.logs[i].S3 && oStrategy.logs[i].S3.support ? oStrategy.logs[i].S3.support : ""
            let s4 = oStrategy.logs[i].S4 && oStrategy.logs[i].S4.support ? oStrategy.logs[i].S4.support : ""
            sLogs = sLogs + "symbol- " + oStrategy.logs[i].symbol + " buy points - " +
              s1 + "," +
              s2 + "," +
              s3 + "," +
              s3 + ","
          }
          oStrategy.logs = sLogs
        }
        if (oStrategy.market === "NIFTY50") {
          sLogs = sLogs + "Thirty- " + oStrategy.high + "," + oStrategy.low + "," +
            "10 mins -" + oStrategy.strikeHigh + "," + oStrategy.strikeLow
          oStrategy.logs = sLogs
        }
        setSelectedAlgo(oStrategy)
        setAlgoName(oStrategy.statName)
        setAlgoType(oStrategy.tradeCategory)
        setMarket(oStrategy.market)
        setAdditional(oStrategy.additionalCoins)
        setRules(oStrategy.rules)

        // setLtp(oStrategy.optionType === "CE" ? oStrategy.settingsBuy.ltp : oStrategy.settingsSell.ltp)
        // setLot(oStrategy.optionType === "CE" ? oStrategy.settingsBuy.lot : oStrategy.settingsSell.lot)
        // setTkp1(oStrategy.settingIntermediateSell.tkp1)
        // setTkp(oStrategy.optionType === "CE" ? oStrategy.settingsBuy.tkp : oStrategy.settingsSell.tkp)
        // setSl(oStrategy.optionType === "CE" ? oStrategy.settingsBuy.sl : oStrategy.settingsSell.sl)
        // setTl(oStrategy.settingTrailingLoss.tl)
        // setPaperTrade(oStrategy.enablePaperTrading)
        // setAlgoActive(oStrategy.active === false ? false : true)
      }

    }
  };
  const handleShowDetails = async () => {
    setOpen(null);
    setObjectPage(true)
    // alert(statId)
  };



  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleOptionsCall = async (event) => {
    setLoading(true);
    if (selected.length == 1) {
      // let responseStat = await addDoc(collection(db, "triggerOrders"), {
      //   order: selected[0] ,
      //   type:"CE",
      //   timeStamp: serverTimestamp(),
      // });
      // WARNING: For POST requests, body is set to null by browsers.
      let data = JSON.stringify({
        "action": "CE"
      });

      let xhr = new XMLHttpRequest();
      xhr.withCredentials = true;

      xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4) {
          console.log(this.responseText);
          setLoading(false);
          alert(this.responseText)
        }
      });
      // let url = "http://localhost:5000/angelone/trade/"+selected[0] 
      let url = "https://up.BuildMyStock.com/angelone/trade/" + selected[0]
      xhr.open("POST", url);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.withCredentials = false;
      xhr.send(data);
    } else {
      return
      alert("Selected more than one record or no records selected")
    }

  };

  const handleOptionsPut = async (event) => {

    setLoading(true);
    if (selected.length == 1) {
      // let responseStat = await addDoc(collection(db, "triggerOrders"), {
      //   order: selected[0] ,
      //   type:"CE",
      //   timeStamp: serverTimestamp(),
      // });
      // WARNING: For POST requests, body is set to null by browsers.
      let data = JSON.stringify({
        "action": "PE"
      });

      let xhr = new XMLHttpRequest();
      xhr.withCredentials = true;

      xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4) {
          console.log(this.responseText);
          setLoading(false);
          alert(this.responseText)
        }
      });
      // let url = "http://localhost:5000/angelone/trade/"+selected[0]
      let url = "https://up.BuildMyStock.com/angelone/trade/" + selected[0]
      xhr.open("POST", url);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.withCredentials = false;
      xhr.send(data);
    } else {
      return
      alert("Selected more than one record or no records selected")
    }

    // if(selected.length == 1){
    //   let responseStat = await addDoc(collection(db, "triggerOrders"), {
    //     order: selected[0] ,
    //     type:"PE",
    //     timeStamp: serverTimestamp(),
    //   });
    // }else{
    //   return
    // }

  };

  const handleEdit = async (event) => {
    setEdit(true)
  };

  const handleSave = async (event) => {
    try {
      const DocRef = doc(db, 'Strategies', statId);
      await updateDoc(DocRef, {
        // "algoName": algoName,
        // "algoType": algoType,
        // "market": market,
        "additionalCoins": additional
        // "rules": rules
      });
      console.log('Algo updated successfully!');
      // selectedAlgo.algoName = algoName
      // selectedAlgo.algoType = algoType
      // selectedAlgo.market = market
      selectedAlgo.additionalCoins = additional
      // selectedAlgo.rules = rules
      setEdit(false)
      alert("Algo updated")
    } catch (error) {
      console.error('Error updating algo: ', error);
      alert
    }

  };

  const handleDisable = async (event) => {
    // alert("Not enabled")
    if (statId) {
      try {
        const DocRef = doc(db, 'strategies', statId);
        updateDoc(DocRef, {
          "active": false
        }).then(() => {
          console.log('Strategy deactivated!');
          setAlgoActive(false)
          alert("Strategy deactivated")
        })
      } catch (error) {
        console.error('Error updating strategy: ', error);
        alert
      }

    }
  };

  const handleActivate = async (event) => {
    // alert("Not enabled")
    if (statId) {
      try {
        const DocRef = doc(db, 'strategies', statId);
        updateDoc(DocRef, {
          "active": true
        }).then(() => {
          console.log('Strategy activated!');
          setAlgoActive(true)
          alert("Strategy activated")
        })
      } catch (error) {
        console.error('Error updating strategy: ', error);
        alert
      }

    }
  };

  const handleManualTrigger = async (event) => {
    // alert("Not enabled")
    if (statId) {
      try {
        const newPostKey = push(child(ref(rdb), 'triggers')).key;
        const updates = {};
        updates['/triggers/' + newPostKey] = { sId: statId }
        update(ref(rdb), updates).then(() => {
          alert("Manual trigger successfully!.Please wait for 5 secs and refresh.")
        })
          .catch((error) => {
            alert("Error in trigger. Contact support")
          });
      } catch (error) {
        console.error('Error', error);
      }
    }
  };

  const handleManualTriggerList = async (aId) => {
    // alert("Not enabled")
    if (aId) {
      try {
        const newPostKey = push(child(ref(rdb), 'triggers')).key;
        const updates = {};
        updates['/triggers/' + newPostKey] = { sId: aId }
        update(ref(rdb), updates).then(() => {
          alert("Manual trigger successfully!.Please wait for 5 secs and refresh.")
        })
          .catch((error) => {
            alert("Error in trigger. Contact support")
          });
      } catch (error) {
        console.error('Error', error);
      }
    }
  };

  const handleDeleteAlgo = async () => {
    // console.log(selected)
    alert("Delete not allowed")
    return;
    try {
      for (let i = 0; i < selected.length; i++) {
        let res = await deleteDoc(doc(db, "strategies", selected[i]));
        console.log("deleted algo :", res);
      }
      const fAlgos = algos.filter(item => !selected.includes(item.id));
      const fUAlgos = uAlgos.filter(item => !selected.includes(item.id));
      const docRef = doc(db, 'users', currentUser.uid);
      let updRes = await updateDoc(docRef, {
        "strategies": fUAlgos
      })
      console.log(updRes)
      let list = []
      // fAlgos.forEach((strategy) => {
      //   const sAvatarUrl = `/assets/images/avatars_exchanges/statergy.svg`;
      //   const oItem = {
      //     avatarUrl: sAvatarUrl,
      //     // name: strategy,
      //     name: strategy.algo,
      //     type: strategy.optionType,
      //     strike: strategy.optionType === "CE" ? settingsBuy.ltp : settingsSell.ltp,
      //     sl: strategy.optionType === "CE" ? settingsBuy.sl : settingsSell.sl,
      //     t1: strategy.optionType === "CE" ? settingsBuy.tkp1 : settingsSell.tkp1,
      //     t2: strategy.optionType === "CE" ? settingsBuy.tkp : settingsSell.tkp,
      //     trail: settingTrailingLoss.tl,
      //     id: doc.id,
      //     exchange: strategy.exchanges[0].exchangeName,
      //     // isVerified: "true",
      //     isVerified: "http://up.BuildMyStock.com/angelone/trade/" + strategy.id,
      //     status: "active"
      //   }
      //   list.push(oItem);
      // });
      // setExchangeList(list)
      setExchangeList(fAlgos)
      setAlgos(fAlgos)
      setUAlgos(fUAlgos)
    } catch (error) {
      alert("Error contact support")
      console.log(error)
    }
  }

  const handleBackToList = () => {
    setObjectPage(false)
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;
  { loading && <CircularProgress /> }
  return (

    // <div>
    // {loading ? (
    //   // <CircularProgress size={200} /> // Loading indicator

    //   <div style={{ textAlign: 'center' }}>
    //   <CircularProgress size={80} style={{ color: '#007bff' }} /> {/* Loading indicator */}
    // </div>

    // ) : (
    <>
      <div style={{ textAlign: 'center' }}>
        {loading && <CircularProgress size={80} style={{ color: 'grey' }} />}
      </div>
      <Helmet>
        <title> Exchanges | BuildMyStock </title>
      </Helmet>
      <Container>
      <Box 
          sx={{ 
            height: '50px', 
            backgroundColor: 'rgb(30, 136, 229)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start', // Aligns the text to the left
            paddingLeft: '16px', // Adds some padding to the left for spacing
            borderRadius: '8px', // Optional: curved edges
            color: 'white', // Text color
            mb: 5
          }}
        >
          <Typography variant="h6">
          My Strategies
          </Typography>
        </Box>
        {!objectPage &&
          <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={5}>
            {/* <Typography variant="h4" gutterBottom>
              My Algos
            </Typography> */}
            {/* <Button variant="contained" onClick={handleOptionsCall} startIcon={<Iconify icon="eva:plus-fill" />}>
          Add Exchange
        </Button> */}
            <Stack direction="row" spacing={2}>
            <Button variant="contained" component={Link} to="/dashboard/addalgo" startIcon={<Iconify icon="eva:plus-fill" />}>
                Add
              </Button>
              {/* <Button variant="contained" disabled={loading} onClick={handleOptionsCall} startIcon={<Iconify icon="eva:play-circle-fill" />}>
            Call Options
            </Button>
            <Button variant="contained" disabled={loading} onClick={handleOptionsPut} startIcon={<Iconify icon="eva:play-circle-fill" />}>
            Put Options
            </Button> */}

              {/* <Button variant="contained" disabled="true" onClick={handleOptionsCall} startIcon={<Iconify icon="eva:play-circle-fill" />}>
                Call Options
              </Button>
              <Button variant="contained" disabled="true" onClick={handleOptionsPut} startIcon={<Iconify icon="eva:play-circle-fill" />}>
                Put Options
              </Button> */}

            </Stack>
            {/* <Button variant="contained" onClick={handleOptionsCall} startIcon={<Iconify icon="eva:arrowhead-right-fill" />}>
          Exit PE
        </Button> */}
          </Stack>
        }
        {!objectPage &&
          <Card>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} onDeleteAlgo={handleDeleteAlgo} />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { id, name, type, market, additional } = row;
                      const selectedUser = selected.indexOf(id) !== -1;
                      // const selectedUser = selected.indexOf(name) !== -1;

                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                          <TableCell padding="checkbox">
                            <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id)} />
                            {/* <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} /> */}
                          </TableCell>

                          {/* <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={name} src={avatarUrl} />
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell> */}

                          {/* <TableCell align="left">{id}</TableCell> */}

                          <TableCell align="left">{name}</TableCell>
                          <TableCell align="left">{type}</TableCell>
                          <TableCell align="left">{market}</TableCell>
                          {/* <TableCell align="left">{additional}</TableCell> */}
                          {/* 
                          <TableCell align="left">
                            <Label color={(status === 'banned' && 'error') || 'success'}>{sentenceCase(status)}</Label>
                          </TableCell> */}


                          <TableCell align="right">
                            {/* <IconButton size="large" color="inherit" onClick={() => handleOpenMenu(event,id)}>
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton> */}
                            {/* <Button variant="contained" size="small" onClick={() => handleShowMoreDetails(event, id)} startIcon={<ReadMoreIcon />}>
                              More
                            </Button> */}
                            <IconButton size="large" color="inherit" onClick={() => handleShowMoreDetails(event, id)}>
                              <Iconify icon={'uiw:d-arrow-right'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={USERLIST.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        }


        {objectPage && selectedAlgo && <Card>
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
              variant="contained"
              onClick={() => handleBackToList()}
              startIcon={<Iconify icon="eva:arrow-left-fill" />}
            >
              Back
            </Button>
          </Box>
          <Stack direction="row" alignItems="center" justifyContent="flex-end" m={2}>
          </Stack>
          <Collapse key={selectedAlgo.statName} in={selectedAlgo.statName !== null} timeout="auto" unmountOnExit>
            <Paper variant="outlined" square style={{ padding: '10px', margin: '10px' }}>
              <Typography variant="h6" component="div">
                Strategy Name : {selectedAlgo.statName}
              </Typography>
              <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={2} mr={2}>
                {/* <IconButton size="small" onClick={() => handleEdit()} color="primary" aria-label="edit">
                  <EditIcon />
                </IconButton>
                <IconButton size="small" onClick={() => handleSave()} color="primary" aria-label="save">
                  <SaveIcon />
                </IconButton> */}
                <Box mr={1} mb={1}>
                  <Button variant="contained" onClick={() => handleEdit()} color="primary" aria-label="edit" startIcon={<EditIcon />}>
                    Edit
                  </Button>
                </Box>
                <Box mb={1}>
                  <Button variant="contained" onClick={() => handleSave()} color="primary" aria-label="save" startIcon={<SaveIcon />}>
                    Save
                  </Button>
                </Box>
              </Stack>
              {/* Display your order logs content here */}
              {/* Example: */}
              <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={12} sm={12} md={4}>
                  <Typography variant="body2" color="text.secondary">
                    Trade Category : {selectedAlgo.tradeCategory}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography variant="body2" color="text.secondary">
                    Market : {selectedAlgo.market}
                  </Typography>
                </Grid>
                {!edit &&
                  <>
                    <Grid item xs={12} sm={12} md={4}>
                      <Typography variant="body2" color="text.secondary">
                        Additional : {selectedAlgo.additionalCoins}
                      </Typography>
                    </Grid>
                  </>
                }
                {edit &&
                  <>
                    <Grid item xs={12} sm={12} md={4}>
                      <TextField
                        fullWidth
                        label="Additional"
                        type="string"
                        variant="outlined"
                        value={additional}
                        size="small"
                        margin="normal"
                        onChange={(e) => setAdditional(e.target.value)}
                      />
                    </Grid>
                  </>
                }

                {/* <Grid item xs={12} sm={12} md={4}>
                  <Typography variant="body2" color="text.secondary">
                    Amount : {selectedAlgo.amount}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography variant="body2" color="text.secondary">
                    Train : {selectedAlgo.train}
                  </Typography>
                </Grid> */}
                <Grid item xs={12} sm={12} md={4}>
                  <Typography variant="body2" color="text.secondary">
                    Rules : {selectedAlgo.rules}
                  </Typography>
                </Grid>
                {/* <Grid item xs={12} sm={12} md={4}>
                  <Typography variant="body2" color="text.secondary">
                    safe factor : {selectedAlgo.safeFactor}
                  </Typography>
                </Grid> */}
                <Grid item xs={12} sm={12} md={4}>
                  <Typography variant="body2" color="text.secondary">
                    Logs : {selectedAlgo.logs}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Collapse>

        </Card>}


      </Container >

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => handleShowDetails()}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      <Dialog aria-labelledby="customized-dialog-title" open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle id="customized-dialog-title">
          Add Exchange
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <AddExchangeForm />
        </DialogContent>
      </Dialog>

    </>
    //   )}
    // </div>

  );
}


{/* <div>
{loading ? (
  <CircularProgress size={80} /> // Loading indicator
) : (
  <>
    content
  </>
)}
</div> */}