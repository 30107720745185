import { faker } from '@faker-js/faker';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  query,
  where,
  getDocs,
  updateDoc,
  getDoc,
  deleteDoc,
  FieldPath,
  documentId
} from "firebase/firestore";

import { getDatabase, ref, set, onValue, update, child, push } from "firebase/database";
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Box,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Collapse,
  Grid,
  Select,
  InputLabel,
  Divider,
  CardContent
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
// Dialog
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';

import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
// sections
import { UserListHead, UserListToolbar, AddExchangeForm } from '../sections/@dashboard/user';
import { auth, db, rdb, storage } from "../firebase";
import { useAuth } from "../context/AuthContext";
import { GifBoxSharp, Margin } from '@mui/icons-material';
// ----------------------------------------------------------------------


const strikePriceCe = [
  {
    value: 4,
    label: "OTM +4"
  },
  {
    value: 3,
    label: "OTM +3"
  },
  {
    value: 2,
    label: "OTM +2"
  },
  {
    value: 1,
    label: "OTM +1"
  },
  {
    value: 0,
    label: "ATM 0"
  },
  {
    value: -1,
    label: "ITM -1"
  },
  {
    value: -2,
    label: "ITM -2"
  },
  {
    value: -3,
    label: "ITM -3"
  },
  {
    value: -4,
    label: "ITM -4"
  }
]

const strikePricePe = [
  {
    value: 4,
    label: "OTM -4"
  },
  {
    value: 3,
    label: "OTM -3"
  },
  {
    value: 2,
    label: "OTM -2"
  },
  {
    value: 1,
    label: "OTM -1"
  },
  {
    value: 0,
    label: "ATM 0"
  },
  {
    value: 1,
    label: "ITM +1"
  },
  {
    value: 2,
    label: "ITM +2"
  },
  {
    value: 3,
    label: "ITM +3"
  },
  {
    value: 4,
    label: "ITM +4"
  }
]

const lotSizes = [
  {
    value: 1,
    label: "1"
  },
  {
    value: 2,
    label: "2"
  },
  {
    value: 3,
    label: "3"
  },
  {
    value: 4,
    label: "4"
  }
]

const TABLE_HEAD = [
  { id: 'name', label: 'Control Plan', alignRight: false },
  { id: 'type', label: 'Type', alignRight: false },
  { id: 'strike', label: 'Strike Price', alignRight: false },
  { id: 'sl', label: 'SL', alignRight: false },
  { id: 't1', label: 'TKP1', alignRight: false },
  { id: 't2', label: 'TKP2', alignRight: false },
  { id: 'trail', label: 'TL', alignRight: false },
  { id: '' },
];

let STATLIST;

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function MyStrategiesPage() {
  const [open, setOpen] = useState(null);
  const [statId, setStatId] = useState(null);
  const [objectPage, setObjectPage] = useState(false)
  const [selectedStat, setSelectedStat] = useState(null)
  const [edit, setEdit] = useState(false)
  const [copied, setCopied] = useState(false)
  const [algos, setAlgos] = useState(null)
  const [uAlgos, setUAlgos] = useState(null)

  const [ltp, setLtp] = useState(null);
  const [lot, setLot] = useState(null);
  const [tkp1, setTkp1] = useState(null);
  const [tkp2, setTkp2] = useState(null);
  const [sl, setSl] = useState(null);
  const [tl, setTl] = useState(null);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openDialog, setOpenDialog] = useState(false);
  const [exchangeList, setExchangeList] = useState([]);
  const [loading, setLoading] = useState(false);

  const { signup, currentUser } = useAuth();

  const getList = async () => {
    const docRef = doc(db, 'Customers', currentUser.uid);
    const docSnap = await getDoc(docRef);
    const list = []
    if (docSnap.exists()) {
      // console.log("Document data:", docSnap.data());
      const aStrategies = docSnap.data().positionControls;
      const aStrategiesIds = aStrategies.map((strategy) => strategy.id)
      setUAlgos(aStrategies)
      const q = query(collection(db, 'PositionControls'), where(documentId(), 'in', aStrategiesIds));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // orders.push({ id: doc.id, ...doc.data() });
        const strategy = doc.data()
        const oItem = {
          avatarUrl: strategy.controlIcon,
          name: strategy.controlName,
          type: strategy.optionType,
          strike: strategy.ltp,
          sl: strategy.sl,
          t1: strategy.tkp1,
          t2: strategy.tkp2,
          trail: strategy.tl,
          id: doc.id,
          // isVerified: "http://up.BuildMyStock.com/angelone/trade/" + strategy.id,
          // status: "active"
        }
        list.push(oItem);
      });
    }
    setAlgos(list)
    setExchangeList(list)
  };

  useEffect(() => {
    const oDoc = getList();
  }, []);

  console.log(exchangeList);
  STATLIST = exchangeList;

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleShowMoreDetails = async (event, id) => {
    setObjectPage(true)
    if (id) {
      setStatId(id)
      const docRef = doc(db, 'PositionControls', id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data());
        const oStrategy = docSnap.data()
        setSelectedStat(oStrategy)
        setLtp(oStrategy.ltp)
        setLot(oStrategy.lot)
        setTkp1(oStrategy.tkp1)
        setTkp2(oStrategy.tkp2)
        setSl(oStrategy.sl)
        setTl(oStrategy.tl)
      }

    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = STATLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleOptionsCall = async (event) => {
    setLoading(true);
    if (selected.length == 1) {
      // let responseStat = await addDoc(collection(db, "triggerOrders"), {
      //   order: selected[0] ,
      //   type:"CE",
      //   timeStamp: serverTimestamp(),
      // });
      // WARNING: For POST requests, body is set to null by browsers.
      let data = JSON.stringify({
        "action": "CE"
      });

      let xhr = new XMLHttpRequest();
      xhr.withCredentials = true;

      xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4) {
          console.log(this.responseText);
          setLoading(false);
          alert(this.responseText)
        }
      });
      // let url = "http://localhost:5000/angelone/trade/"+selected[0] 
      let url = "https://up.BuildMyStock.com/angelone/trade/" + selected[0]
      xhr.open("POST", url);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.withCredentials = false;
      xhr.send(data);
    } else {
      return
      alert("Selected more than one record or no records selected")
    }
  };

  const handleOptionsPut = async (event) => {

    setLoading(true);
    if (selected.length == 1) {
      // let responseStat = await addDoc(collection(db, "triggerOrders"), {
      //   order: selected[0] ,
      //   type:"CE",
      //   timeStamp: serverTimestamp(),
      // });
      // WARNING: For POST requests, body is set to null by browsers.
      let data = JSON.stringify({
        "action": "PE"
      });

      let xhr = new XMLHttpRequest();
      xhr.withCredentials = true;

      xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4) {
          console.log(this.responseText);
          setLoading(false);
          alert(this.responseText)
        }
      });
      // let url = "http://localhost:5000/angelone/trade/"+selected[0]
      let url = "https://up.BuildMyStock.com/angelone/trade/" + selected[0]
      xhr.open("POST", url);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.withCredentials = false;
      xhr.send(data);
    } else {
      return
      alert("Selected more than one record or no records selected")
    }

    // if(selected.length == 1){
    //   let responseStat = await addDoc(collection(db, "triggerOrders"), {
    //     order: selected[0] ,
    //     type:"PE",
    //     timeStamp: serverTimestamp(),
    //   });
    // }else{
    //   return
    // }

  };

  const handleEdit = async (event) => {
    setEdit(true)
  };

  const handleSave = async (event) => {
    try {
      const DocRef = doc(db, 'PositionControls', statId);
      await updateDoc(DocRef, {
        "ltp": parseInt(ltp),
        "lot": parseInt(lot),
        "tkp1": parseInt(tkp1),
        "tkp2": parseInt(tkp2),
        "sl": parseInt(sl),
        "tl": parseInt(tl),
        // "settingIntermediateSell.tkp1": parseInt(tkp1),
        // "settingsBuy.tkp": parseInt(tkp),
        // "settingsBuy.sl": parseInt(sl),
        // "settingTrailingLoss.tl": parseInt(tl),
        // "enablePaperTrading": paperTrade
      });
      console.log('Strategy updated successfully!');
      selectedStat.ltp = parseInt(ltp)
      selectedStat.lot = parseInt(lot)
      selectedStat.tkp1 = parseInt(tkp1)
      selectedStat.tkp2 = parseInt(tkp2)
      selectedStat.sl = parseInt(sl)
      selectedStat.tl = parseInt(tl)
      // selectedStat.settingsBuy.ltp = parseInt(ltp)
      // selectedStat.settingsBuy.lot = parseInt(lot)
      // selectedStat.settingIntermediateSell.tkp1 = parseInt(tkp1)
      // selectedStat.settingsBuy.tkp = parseInt(tkp)
      // selectedStat.settingsBuy.sl = parseInt(sl)
      // selectedStat.settingTrailingLoss.tl = parseInt(tl)        
      // selectedStat.enablePaperTrading = paperTrade
      setEdit(false)
      alert("Strategy updated")
    } catch (error) {
      console.error('Error updating strategy: ', error);
      alert
    }

  };

  const handleDisable = async (event) => {
    if (statId) {
      try {
        const DocRef = doc(db, 'PositionControls', statId);
        updateDoc(DocRef, {
          "active": false
        }).then(() => {
          console.log('Strategy deactivated!');
          setAlgoActive(false)
          alert("Strategy deactivated")
        })
      } catch (error) {
        console.error('Error updating strategy: ', error);
        alert
      }

    }
  };

  const handleActivate = async (event) => {
    // alert("Not enabled")
    if (statId) {
      try {
        const DocRef = doc(db, 'PositionControls', statId);
        updateDoc(DocRef, {
          "active": true
        }).then(() => {
          console.log('Strategy activated!');
          setAlgoActive(true)
          alert("Strategy activated")
        })
      } catch (error) {
        console.error('Error updating strategy: ', error);
        alert
      }

    }
  };

  const handleManualTrigger = async (event) => {
    // alert("Not enabled")
    if (statId) {
      try {
        const newPostKey = push(child(ref(rdb), 'triggers')).key;
        const updates = {};
        updates['/triggers/' + newPostKey] = { sId: statId }
        update(ref(rdb), updates).then(() => {
          alert("Manual trigger successfully!.Please wait for 5 secs and refresh.")
        })
          .catch((error) => {
            alert("Error in trigger. Contact support")
          });
      } catch (error) {
        console.error('Error', error);
      }
    }
  };

  const handleManualTriggerList = async (aId) => {
    if (aId) {
      try {
        const newPostKey = push(child(ref(rdb), 'triggers')).key;
        const updates = {};
        updates['/triggers/' + newPostKey] = { sId: aId }
        update(ref(rdb), updates).then(() => {
          alert("Manual trigger successfully!.Please wait for 5 secs and refresh.")
        })
          .catch((error) => {
            alert("Error in trigger. Contact support")
          });
      } catch (error) {
        console.error('Error', error);
      }
    }
  };

  const handleBackToList = () => {
    setObjectPage(false)
  };

  const handleDeleteAlgo = async () => {
    // console.log(selected)
    try {
      for (let i = 0; i < selected.length; i++) {
        let res = await deleteDoc(doc(db, "PositionControls", selected[i]));
        console.log("deleted algo :", res);
      }
      const fAlgos = algos.filter(item => !selected.includes(item.id));
      const fUAlgos = uAlgos.filter(item => !selected.includes(item.id));
      const docRef = doc(db, 'Customers', currentUser.uid);
      let updRes = await updateDoc(docRef, {
        "strategies": fUAlgos
      })
      console.log(updRes)
      let list = []
      setExchangeList(fAlgos)
      setAlgos(fAlgos)
      setUAlgos(fUAlgos)
    } catch (error) {
      alert("Error contact support")
      console.log(error)
    }
  }

  const handleCancel = (event) => {
    setLtp(selectedStat.ltp)
    setLot(selectedStat.lot)
    setTkp1(selectedStat.tkp1)
    setTkp2(selectedStat.tkp2)
    setSl(selectedStat.sl)
    setTl(selectedStat.tl)
    setEdit(false)
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - STATLIST.length) : 0;

  const filteredUsers = applySortFilter(STATLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;
  { loading && <CircularProgress /> }
  return (
    <>
      <div style={{ textAlign: 'center' }}>
        {loading && <CircularProgress size={80} style={{ color: 'grey' }} />}
      </div>
      <Helmet>
        <title> Exchanges | BuildMyStock </title>
      </Helmet>
      <Container>
        <Box
          sx={{
            height: '50px',
            backgroundColor: 'rgb(30, 136, 229)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start', // Aligns the text to the left
            paddingLeft: '16px', // Adds some padding to the left for spacing
            borderRadius: '8px', // Optional: curved edges
            color: 'white', // Text color
            mb: 5
          }}
        >
          <Typography variant="h6">
            My Position Control Plans
          </Typography>
        </Box>
        {!objectPage &&
          <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={5}>
            <Stack direction="row" spacing={2}>
              <Button variant="contained" component={Link} to="/dashboard/addstrategy" startIcon={<Iconify icon="eva:plus-fill" />}>
                Add
              </Button>
            </Stack>
          </Stack>
        }
        {!objectPage &&
          <Card>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} onDeleteAlgo={handleDeleteAlgo} />
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={STATLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { id, name, type, strike, sl, t1, t2, trail, exchange, role, status, company, avatarUrl, isVerified } = row;
                      const selectedUser = selected.indexOf(id) !== -1;
                      // const selectedUser = selected.indexOf(name) !== -1;
                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                          <TableCell padding="checkbox">
                            <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id)} />
                            {/* <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} /> */}
                          </TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={name} src={avatarUrl} />
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{type}</TableCell>
                          <TableCell align="left">{strike}</TableCell>
                          <TableCell align="left">{sl}</TableCell>
                          <TableCell align="left">{t1}</TableCell>
                          <TableCell align="left">{t2}</TableCell>
                          <TableCell align="left">{trail}</TableCell>
                          <TableCell align="right">
                            <IconButton size="large" color="inherit" onClick={() => handleShowMoreDetails(event, id)}>
                              <Iconify icon={'uiw:d-arrow-right'} />
                            </IconButton>
                            {/* <Button variant="contained" size="small" onClick={() => handleShowMoreDetails(event, id)} endIcon={<Iconify icon="eva:arrow-left-fill" />}/> */}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={STATLIST.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        }

        {objectPage && selectedStat && <Card>

          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
              variant="contained"
              onClick={() => handleBackToList()}
              startIcon={<Iconify icon="eva:arrow-left-fill" />}
            >
              Back
            </Button>
          </Box>
          <Collapse key={selectedStat.statName} in={selectedStat.statName !== null} timeout="auto" unmountOnExit>
            <Paper variant="outlined" square style={{ padding: '10px', margin: '10px' }}>
              <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={2} mr={2}>
                <Box>
                  <Button variant="contained" onClick={() => handleEdit()} color="primary" aria-label="edit" startIcon={<EditIcon />}>
                    Edit
                  </Button>
                </Box>
                <Box sx={{ ml: 1 }}>
                  <Button variant="contained" onClick={() => handleCancel()} color="primary" aria-label="edit" startIcon={<Iconify icon="mdi:cancel-bold" />}>
                    Cancel
                  </Button>
                </Box>
              </Stack>
              <Typography variant="h6" component="div" sx={{ mt: 2 }} >
                {selectedStat.controlName}
              </Typography>
              <Divider sx={{ mb: 2 }} />
              {/* Container for General Info */}
              <Grid container spacing={2} alignItems="center" justifyContent="left" sx={{mb:2}}>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Typography variant="body2" color="textSecondary" component="p" marginTop={2} >
                    General Info :
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3} md={3} sx={{ml:2}}>
                  {/* <Card
                    sx={{
                      display: selectedStat.controlIcon === "" ? "none" : "inherit",
                      // padding: '16px',
                      textAlign: 'center', // Centers the avatar
                      boxShadow: 3, // Optional: Adds shadow to the card
                      backgroundColor: '#f0f8ff', // Light background color (Alice Blue)
                      // borderRadius: '8px', 
                    }}
                  >
                    <CardContent>
                      <Avatar
                        alt="exchange icon"
                        src={selectedStat.controlIcon}
                        sx={{
                          // width: 56,
                          // height: 56,
                          width: '80%',
                          height: '80%',
                          margin: 'auto', // Centers the avatar horizontally
                        }}
                      />
                    </CardContent>
                  </Card> */}
                  <Avatar
                    alt="exchange icon"
                    src={selectedStat.controlIcon}
                    sx={{
                      // width: 56,
                      // height: 56,
                      width: '40%',
                      height: '40%',
                      // margin: 'auto', 
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography variant="body2" color="text.secondary">
                    Trade Category : {selectedStat.tradeCategory}
                  </Typography>
                </Grid>
              </Grid>
              {/* Container for Entry Conditions */}
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Typography variant="body2" color="textSecondary" component="p" marginTop={2} >
                    Entry Conditions :
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography variant="body2" color="text.secondary">
                    Transaction Type : {selectedStat.transaction}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography variant="body2" color="text.secondary">
                    Order Type : {selectedStat.orderType}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="body2" color="text.secondary">
                    Option Type : {selectedStat.optionType}
                  </Typography>
                </Grid>
                {!edit &&
                  <>
                    <Grid item xs={6} sm={3} md={3}>
                      <Typography variant="body2" color="text.secondary">
                        {/* Strike Price (LTP): {selectedStat.optionType === "CE" ? selectedStat.settingsBuy.ltp : selectedStat.settingsSell.ltp} */}
                        Strike Price (LTP): {selectedStat.ltp}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                      <Typography variant="body2" color="text.secondary">
                        Lot Size: {selectedStat.lot}
                      </Typography>
                    </Grid>
                  </>
                }
                {edit &&
                  <>
                    {(selectedStat.optionType === "CE") && (
                      <>
                        <Grid item xs={6} sm={3} md={3}>
                          <TextField
                            fullWidth
                            label="Strike Price (LTP)"
                            type="number"
                            select
                            variant="outlined"
                            value={ltp}
                            size="small"
                            margin="normal"
                            onChange={(e) => setLtp(e.target.value)}
                          >
                            {strikePriceCe.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        {/* <Grid item xs={12} md={6} lg={6} >
                          <Field
                            as={TextField}
                            label="Strike Price (LTP)"
                            type="number"
                            select
                            defaultValue={ltp}
                            fullWidth
                            // error={props.errors.ltp && props.touched.ltp}
                            // helperText={<ErrorMessage name="ltp" />}
                            // required
                            style={fieldStyle}
                          >
                            {strikePriceCe.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid> */}
                      </>
                    )}

                    {(selectedStat.optionType === "PE") && (
                      <>
                        <Grid item xs={6} md={3} lg={3} >
                          <TextField
                            fullWidth
                            label="Strike Price (LTP)"
                            type="number"
                            select
                            variant="outlined"
                            value={ltp}
                            size="small"
                            margin="normal"
                            onChange={(e) => setLtp(e.target.value)}
                          >
                            {strikePricePe.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>

                          {/* <Field
                            as={TextField}
                            name="ltp"
                            label="Strike Price (LTP)"
                            type="number"
                            select
                            defaultValue={0}
                            fullWidth
                            error={props.errors.ltp && props.touched.ltp}
                            helperText={<ErrorMessage name="ltp" />}
                            // required
                            style={fieldStyle}
                          >
                            {strikePricePe.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Field> */}
                        </Grid>
                      </>
                    )}
                    <Grid item xs={6} sm={3} md={3}>
                      <TextField
                        fullWidth
                        label="Lot"
                        type="number"
                        variant="outlined"
                        select
                        value={lot}
                        size="small"
                        margin="normal"
                        onChange={(e) => setLot(e.target.value)}
                      >
                        {lotSizes.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}

                      </TextField>
                    </Grid>
                  </>
                }
              </Grid>

              {/* Container for Exit Conditions */}
              <Grid container spacing={2}>

                <Grid item xs={12} sx={{ mb: 1 }}>
                  <Typography variant="body2" color="textSecondary" component="p" marginTop={3} >
                    Exit Conditions :
                  </Typography>
                </Grid>

                {!edit &&
                  <>
                    <Grid item xs={6} sm={3} md={3}>
                      <Typography variant="body2" color="text.secondary">
                        Take Profit 1: {selectedStat.tkp1}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                      <Typography variant="body2" color="text.secondary">
                        Take Profit 2: {selectedStat.tkp2}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                      <Typography variant="body2" color="text.secondary">
                        Stop Loss: {selectedStat.sl}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                      <Typography variant="body2" color="text.secondary">
                        Trailing Loss: {selectedStat.tl}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Box>
                        {copied ? <span style={{ color: 'red' }}>Copied.</span> : null}
                      </Box>
                    </Grid>
                  </>}

                {edit &&
                  <>
                    <Grid item xs={6} sm={3} md={3}>
                      <TextField
                        fullWidth
                        label="Take profit1 (Partial Sell)"
                        type="number"
                        variant="outlined"
                        value={tkp1}
                        size="small"
                        margin="normal"
                        onChange={(e) => setTkp1(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                      <TextField
                        fullWidth
                        label="Take Profit 2"
                        type="number"
                        variant="outlined"
                        value={tkp2}
                        size="small"
                        margin="normal"
                        onChange={(e) => setTkp2(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                      <TextField
                        fullWidth
                        label="Stop Loss"
                        type="number"
                        variant="outlined"
                        value={sl}
                        size="small"
                        margin="normal"
                        onChange={(e) => setSl(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                      <TextField
                        fullWidth
                        label="Trailing Loss"
                        type="number"
                        variant="outlined"
                        value={tl}
                        size="small"
                        margin="normal"
                        onChange={(e) => setTl(e.target.value)}
                      />
                    </Grid>
                  </>}
              </Grid>
              <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{mt:2}}>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button variant="contained" onClick={() => handleSave()} color="primary" aria-label="save" startIcon={<Iconify icon="material-symbols:save" />}>
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Collapse>
        </Card>}
      </Container >
    </>
  );
}
