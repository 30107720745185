// firebase
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  query,
  where,
  getDocs,
  updateDoc,
  getDoc
} from "firebase/firestore";
import React from 'react';
import {
  Grid, TextField, Button, Card, CardContent, Typography, MenuItem, FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { auth, db, storage } from "../../../firebase";
import { useAuth } from "../../../context/AuthContext";

//Fields - statName,tradeCategory,transaction, optionType, Market (NIFTY50, crypto coins),exchange, rules,logs,tradeEngines

const exchanges = [
  {
    value: 'Binance',
    label: 'Binance',
  },
  {
    value: 'Bybit',
    label: 'Bybit',
  },
  {
    value: 'Angelone',
    label: 'Angelone',
  }
];

const tradeCategories = [
  {
    value: 'Options',
    label: 'Options',
  }
  // ,
  // {
  //   value: 'CryptoFutures',
  //   label: 'Crypto Futures',
  // },
];

const MarketTypes = [
  {
    value: 'NIFTY50',
    label: 'NIFTY50',
  }
];

function CreateAlgoForm() {
  const { signup, currentUser } = useAuth();
  const paperStyle = { padding: '0 15px 40px 15px', width: 250 };
  const btnStyle = { marginTop: 10 };
  const fieldStyle = { marginTop: 10 };
  const phoneRegExp = /^[2-9]{2}[0-9]{8}/;
  const passwordRegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  const initialValues = {
    statName: '',
    tradeCategory:'',
    transaction:'',
    optionType:'',
    market:'',
    exchange:'',
    rules:'',
    // logs:'',
    // tradeEngines:[]
  };

  const transactionOptions = ['Buy', 'Sell'];
  const orderOptions = ['Market', 'Limit'];
  const optionTypes = ['CE', 'PE', 'Both'];
  const booleanTypes = ['Yes', 'No']
  const validationSchema = Yup.object().shape({
    // exchange: Yup.string().required('Required'),
    // tradetype: Yup.string().required('Required'),
    // algo: Yup.string().required('Required'),
    // coin: Yup.string().required('Required'),
    /*
    exchange: Yup.string().min(3, "It's too short").required('Required'),
    email: Yup.string().email('Enter valid email').required('Required'),
    // phoneNumber: Yup.number().typeError("Enter valid Phone number").required("Required"),
    phoneNumber: Yup.string().matches(phoneRegExp, 'Enter valid Phone number').required('Required'),
    password: Yup.string()
      .min(8, 'Minimum characters should be 8')
      .matches(passwordRegExp, 'Password must have one upper, lower case, number, special symbol')
      .required('Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Password not matches')
      .required('Required'),
      */
  });
  const onSubmit = async (values, props) => {
    // console.log(values)
    // alert(JSON.stringify(values), null, 2);
    try {
      const docRef = doc(db, 'Customers', currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        let sCollectionName = "";
        sCollectionName = "Strategies"
        let rules = values.rules.split(",");  // Split the string by comma
        let responseStat = await addDoc(collection(db, sCollectionName), {
          statName: values.statName,
          tradeCategory: values.tradeCategory,
          transaction: values.transaction,
          optionType: values.optionType,
          market: values.market,
          exchange: values.exchange,
          rules: rules,
          logs: [],
          tradeEngines:[],
          createdBy: currentUser.uid,
          createdTimeStamp: serverTimestamp(),
          lastChangedBy:'',
          lastChangedTimeStamp: serverTimestamp()
        });
        let aAlgos = docSnap.data().strategies;
        if (aAlgos) {
          aAlgos.push({
            name: values.statName,
            id: responseStat.id
          })
        } else {
          aAlgos = []
          aAlgos.push({
            name: values.statName,
            id: responseStat.id
          })
        }
        await updateDoc(docRef, {
          strategies: aAlgos,
        });
        console.log(responseStat);
        console.log("Document written with ID: ", responseStat.id);
        alert("Successfull" + responseStat.id);
        props.resetForm();
      } else {
        // doc.data() will be undefined in this case
        alert('No exchanges linked please add your exchange');
        props.resetForm();
      }
    } catch (error) {
      console.log(error)
      alert("Failed. Please contact support")
    }
  };
  return (
    <div className="App">
      <Grid>
        <Card style={{ maxWidth: 750, padding: '20px 5px', margin: '0 auto' }}>
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
              Strategy build Info
            </Typography>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
              {(props) => (
                <Form noValidate>
                  <Grid container spacing={1}>
                    <Grid item xs={3}>
                      <Field
                        as={TextField}
                        name="statName"
                        label="Stratergy Name"
                        type="text"
                        fullWidth
                        error={props.errors.statName && props.touched.statName}
                        helperText={<ErrorMessage name="statName" />}
                        // required
                        style={fieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        id="outlined-select-tradeCategory"
                        select
                        label="Trade Category"
                        defaultValue="Standard"
                        helperText="Please select"
                        required
                        value={props.values.tradeCategory}
                        style={fieldStyle}
                        name="tradeCategory"
                        error={props.errors.tradeCategory && props.touched.tradeCategory}
                      >
                        {tradeCategories.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>

                    {/* start item transaction */}
                    <Grid item xs={12} md={3} lg={3}>
                      {/* <FormLabel component="legend">Select Transaction Type</FormLabel> */}
                      <Typography variant="body2" color="textSecondary" component="p"
                        sx={{ mt: 1 }} >
                        Transaction :
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9} lg={9}>
                      <Field name="transaction" >
                        {({ field }) => (
                          <RadioGroup
                            row
                            aria-label="transaction type"
                            {...field}
                          >
                            {transactionOptions.map((option) => (
                              <FormControlLabel
                                key={option}
                                value={option}
                                control={<Radio />}
                                label={option}
                                disabled={option === 'Sell'}  // Disable "Sell" option
                              />
                            ))}
                          </RadioGroup>
                        )}
                      </Field>
                      <ErrorMessage name="transaction" component="div" />
                    </Grid>
                    {/* end */}
                    {/* start option type */}
                    <Grid item xs={12} md={3} lg={3}>
                      {/* <FormLabel component="legend">Select Transaction Type</FormLabel> */}
                      <Typography variant="body2" color="textSecondary" component="p" gutterBottom
                        sx={{ mt: 1 }}>
                        Option Type :
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9} lg={9}>
                      <Field name="optionType">
                        {({ field }) => (
                          <RadioGroup
                            row
                            aria-label="option type"
                            {...field}
                          >
                            {optionTypes.map((option) => (
                              <FormControlLabel
                                key={option}
                                value={option}
                                control={<Radio />}
                                label={option}
                              />
                            ))}
                          </RadioGroup>
                        )}
                      </Field>
                      <ErrorMessage name="optionType" component="div" />
                    </Grid>
                    {/* end */}

                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        id="outlined-select-market"
                        select
                        label="Market"
                        defaultValue="Option"
                        helperText="Please select your market"
                        required
                        value={props.values.market}
                        style={fieldStyle}
                        name="market"
                        error={props.errors.market && props.touched.market}
                      >
                        {MarketTypes.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        id="outlined-select-exchange"
                        select
                        label="Exchange"
                        defaultValue="Standard"
                        helperText="Please select"
                        required
                        value={props.values.exchange}
                        style={fieldStyle}
                        name="exchange"
                        error={props.errors.exchange && props.touched.exchange}
                      >
                        {exchanges.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        name="rules"
                        label="Rules"
                        helperText="Seperate rules by comma"
                        type="text"
                        fullWidth
                        error={props.errors.rules && props.touched.rules}
                        // required
                        style={fieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button type="submit" variant="contained" color="primary" >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
}

export default CreateAlgoForm;
{/* <Grid item xs={12}>
                      <Field
                        as={TextField}
                        id="outlined-select-tradetype"
                        select
                        label="Trade Type"
                        helperText={<ErrorMessage name="tradetype" />}
                        required
                        value={props.values.tradetype}
                        style={fieldStyle}
                        name="tradetype"
                        error={props.errors.tradetype&&props.touched.tradetype}
                        fullWidth
                      >
                        {tradetypes.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        id="outlined-select-algo"
                        select
                        label="Algo"
                        helperText={<ErrorMessage name="algo" />}
                        required
                        value={props.values.algo}
                        style={fieldStyle}
                        name="algo"
                        error={props.errors.algo&&props.touched.algo}
                        fullWidth
                      >
                        {algo.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>  
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        id="outlined-select-coin"
                        select
                        label="Coin"
                        helperText={<ErrorMessage name="coin" />}
                        required
                        value={props.values.coin}
                        style={fieldStyle}
                        name="coin"
                        error={props.errors.coin&&props.touched.coin}
                        fullWidth
                      >
                        {coin.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>                     */}