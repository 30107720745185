// firebase
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  query,
  where,
  getDocs,
  updateDoc,
  getDoc
} from "firebase/firestore";
import React from 'react';
import {
  Grid, TextField, Button, Card, CardContent, Typography, MenuItem, FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  ListItemIcon
} from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Iconify from '../../../components/iconify';
import * as Yup from 'yup';
import { auth, db, storage } from "../../../firebase";
import { useAuth } from "../../../context/AuthContext";
import { useEffect, useState } from 'react';
import { arEG } from "date-fns/locale";

const EngineTypes = [
  {
    value: 'ST',
    label: 'Static Trading',
  },
  {
    value: 'AT',
    label: 'Startegy/Algo Trading',
  },
];

const MarketTypes = [
  {
    value: 'NIFTY50',
    label: 'NIFTY50',
  }
];

const engineIcons = [
  { value: 'e1', label: 'e1', icon: "/assets/engine_icons/e1.png" },
  { value: 'e2', label: 'e2', icon: "/assets/engine_icons/e2.png" },
  { value: 'e3', label: 'e3', icon: "/assets/engine_icons/e3.png" },
  { value: 'e4', label: 'e4', icon: "/assets/engine_icons/e4.png" },
  { value: 'e5', label: 'e5', icon: "/assets/engine_icons/e5.png" },
  { value: 'e6', label: 'e6', icon: "/assets/engine_icons/e6.png" },
  { value: 'e7', label: 'e7', icon: "/assets/engine_icons/e7.png" },
  { value: 'e8', label: 'e8', icon: "/assets/engine_icons/e8.png" },
  { value: 'e9', label: 'e9', icon: "/assets/engine_icons/e9.png" },
  { value: 'e10', label: 'e10', icon: "/assets/engine_icons/e10.png" },
];


function CreateTradeEngineForm() {

  const { signup, currentUser } = useAuth();
  const [myAlgos, setMyAlgos] = useState([]);
  const [myStrategies, setMyStrategies] = useState([]);
  const [myExchanges, setMyExchanges] = useState([]);

  const [selectedControl, setSelectedControl] = useState('');

  const [selectedStrategy, setSelectedStrategy] = useState('');
  const [selectedControlCE, setSelectedControlCE] = useState('');
  const [selectedControlPE, setSelectedControlPE] = useState('');

  const paperStyle = { padding: '0 15px 40px 15px', width: 250 };
  const btnStyle = { marginTop: 10 };
  const fieldStyle = { marginTop: 10 };
  const phoneRegExp = /^[2-9]{2}[0-9]{8}/;
  const passwordRegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  const initialValues = {
    tradeEngineName: '',
    engineIcon: "/assets/engine_icons/e1.png",
    engineType: '',
    strategy: '',
    control: '',
    tradeCategory: '',// from control
    transaction: '', // from control
    optionType: '', // from control
    market: '', // from strategy
    statControlCE: '',
    statControlPE: '',
    exchange: '',
    enablePaperTrade: true,
  };
  const transactionOptions = ['Buy', 'Sell'];
  const orderOptions = ['Market', 'Limit'];
  const optionTypes = ['CE', 'PE', 'Both'];
  const booleanTypes = ['Yes', 'No']
  const validationSchema = Yup.object().shape({
    // exchange: Yup.string().required('Required'),
    // tradetype: Yup.string().required('Required'),
    // algo: Yup.string().required('Required'),
    // coin: Yup.string().required('Required'),
    /*
    exchange: Yup.string().min(3, "It's too short").required('Required'),
    email: Yup.string().email('Enter valid email').required('Required'),
    // phoneNumber: Yup.number().typeError("Enter valid Phone number").required("Required"),
    phoneNumber: Yup.string().matches(phoneRegExp, 'Enter valid Phone number').required('Required'),
    password: Yup.string()
      .min(8, 'Minimum characters should be 8')
      .matches(passwordRegExp, 'Password must have one upper, lower case, number, special symbol')
      .required('Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Password not matches')
      .required('Required'),
      */
  });


  const onSubmit = async (values, props) => {
    // console.log(values)
    // alert(JSON.stringify(values), null, 2);
    try {
      if (!values.control && !values.strategy) {
        alert("No Strategy or Algo selected atleast one is needed for creation Trade Engine")
        return
      }
      const docRef = doc(db, 'Customers', currentUser.uid);
      const docSnap = await getDoc(docRef);
      const aBrokers = []
      let selectedExchange = myExchanges.find((item) => item.value === values.exchange);

      const oBroker = {
        customer: currentUser.uid,
        exchangeName: selectedExchange.exchangeName,
        exchangeId: values.exchange
      }
      aBrokers.push(oBroker)

      // const statRefCE = values.controlCE ? doc(db, 'Strategies', values.controlCE) : null;
      // const statSnapCE = values.controlCE ? await getDoc(statRefCE) : null;
      // const oRefStatCE = values.controlCE ? statSnapCE.data() : {}

      let oLinkedStat = {
        "controlCEId": values.statControlCE,
        "controlCE": selectedControlCE,
        "controlPEId":values.statControlPE,
        "controlPE": selectedControlPE,
        "strategy": selectedStrategy
      }

      if (docSnap.exists()) {
        let sCollectionName = "";
        sCollectionName = "TradeEngines"
        let responseStat = await addDoc(collection(db, sCollectionName), {
          tradeEngineName: values.tradeEngineName,
          engineIcon: values.engineIcon,
          engineType: values.engineType,
          linkedStrategyId: values.strategy,
          linkedControlId: values.control,
          tradeCategory: values.tradeCategory,
          transaction: values.transaction,
          optionType: values.optionType,
          market: values.market,
          enablePaperTrade: values.enablePaperTrade,
          MasterCustomer: currentUser.uid,
          MasterExchangeName: selectedExchange.exchangeName,
          MasterExchangeId: values.exchange,
          active: true,
          timeStamp: serverTimestamp(),
          linkedControl: selectedControl ? selectedControl : '',
          linkedStat: oLinkedStat ? oLinkedStat : '',
          linkedExchanges: oBroker ? [oBroker] : [],
          createdBy: currentUser.uid,
          createdTimeStamp: serverTimestamp(),
          lastChangedBy: '',
          lastChangedTimeStamp: serverTimestamp()
        });
        let aAlgos = docSnap.data().tradeEngines;
        if (aAlgos) {
          aAlgos.push({
            name: values.tradeEngineName,
            id: responseStat.id
          })
        } else {
          aAlgos = []
          aAlgos.push({
            name: values.tradeEngineName,
            id: responseStat.id
          })
        }
        await updateDoc(docRef, {
          tradeEngines: aAlgos,
        });
        if (values.strategy) {
          const statRef = doc(db, 'Strategies', values.strategy);
          const statSnap = await getDoc(statRef);
          let aAlgoEngines = statSnap.data().tradeEngines;
          if (aAlgoEngines) {
            aAlgoEngines.push({
              name: values.tradeEngineName,
              id: responseStat.id
            })
          } else {
            aAlgoEngines = []
            aAlgoEngines.push({
              name: values.tradeEngineName,
              id: responseStat.id
            })
          }
          await updateDoc(statRef, {
            tradeEngines: aAlgoEngines,
          });
        }

        if (values.statControlCE) {
          const statCERef = doc(db, 'PositionControls', values.statControlCE);
          const statCESnap = await getDoc(statCERef);
          let aAlgoEngines = statCESnap.data().tradeEngines;
          if (aAlgoEngines) {
            aAlgoEngines.push({
              name: values.tradeEngineName,
              id: responseStat.id
            })
          } else {
            aAlgoEngines = []
            aAlgoEngines.push({
              name: values.tradeEngineName,
              id: responseStat.id
            })
          }
          await updateDoc(statCERef, {
            tradeEngines: aAlgoEngines,
          });
        }

        if (values.statControlPE) {
          const statPERef = doc(db, 'PositionControls', values.statControlPE);
          const statPESnap = await getDoc(statPERef);
          let aAlgoEngines = statPESnap.data().tradeEngines;
          if (aAlgoEngines) {
            aAlgoEngines.push({
              name: values.tradeEngineName,
              id: responseStat.id
            })
          } else {
            aAlgoEngines = []
            aAlgoEngines.push({
              name: values.tradeEngineName,
              id: responseStat.id
            })
          }
          await updateDoc(statPERef, {
            tradeEngines: aAlgoEngines,
          });
        }

        console.log(responseStat);
        console.log("Document written with ID: ", responseStat.id);
        alert("Successfull" + responseStat.id);
        props.resetForm();
      } else {
        // doc.data() will be undefined in this case
        alert('No exchanges linked please add your exchange');
        props.resetForm();
      }
    } catch (error) {
      console.log(error)
      alert("Failed. Please contact support")
    }
  };

  useEffect(() => {
    // Fetch data from the 'orders' collection
    const fetchData = async () => {

      const docRef = doc(db, 'Customers', currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        let aAlgos = []
        let aStat = []
        let aExchanges = []
        for (let i = 0; i < docSnap.data().positionControls.length; i++) {
          let oStat = {}
          oStat.value = docSnap.data().positionControls[i].id
          oStat.label = docSnap.data().positionControls[i].name
          aStat.push(oStat)
        }
        for (let i = 0; i < docSnap.data().strategies.length; i++) {
          let oAlgo = {}
          oAlgo.value = docSnap.data().strategies[i].id
          oAlgo.label = docSnap.data().strategies[i].name
          aAlgos.push(oAlgo)
        }
        for (let i = 0; i < docSnap.data().exchanges.length; i++) {
          let oExchange = {}
          oExchange.value = docSnap.data().exchanges[i].exchangeId
          oExchange.label = docSnap.data().exchanges[i].name
          oExchange.exchangeName = docSnap.data().exchanges[i].exchangeName
          aExchanges.push(oExchange)
        }
        setMyAlgos(aAlgos)
        setMyStrategies(aStat)
        setMyExchanges(aExchanges)
      }

    };

    fetchData();
  }, []);
  return (
    <div className="App">
      <Grid>
        <Card style={{ maxWidth: 750, padding: '20px 5px', margin: '0 auto' }}>
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
              Trade Engine Info
            </Typography>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
              {(props) => (
                <Form noValidate>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6} lg={6}>
                      <Field
                        as={TextField}
                        name="tradeEngineName"
                        label="Trade Engine Name"
                        type="text"
                        fullWidth
                        error={props.errors.tradeEngineName && props.touched.tradeEngineName}
                        helperText={<ErrorMessage name="tradeEngineName" />}
                        // required
                        style={fieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} lg={2}  >
                      <Field
                        as={TextField}
                        fullWidth
                        id="outlined-select-engineIcon"
                        select
                        label="Engine Icon"
                        required
                        value={props.values.engineIcon}
                        style={fieldStyle}
                        name="engineIcon"
                        error={props.errors.engineIcon && props.touched.engineIcon}
                      >
                        {engineIcons.map((option) => (
                          <MenuItem key={option.icon} value={option.icon}>
                            <ListItemIcon>
                              <img src={option.icon} alt={option.label} style={{ width: 34, height: 34 }} />
                            </ListItemIcon>
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        id="outlined-select-enginetype"
                        select
                        label="Select Engine Type"
                        required
                        fullWidth
                        style={fieldStyle}
                        name="engineType"
                        error={props.errors.engineType && props.touched.engineType}
                      >
                        {EngineTypes.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>

                    {(props.values.engineType === "ST") &&
                      <>
                        <Grid item xs={12}>
                          <Field
                            as={TextField}
                            id="outlined-select-control"
                            select
                            label="Select Position Control"
                            helperText="Please select your control to be linked"
                            // required
                            style={fieldStyle}
                            name="control"
                            error={props.errors.control && props.touched.control}
                            onChange={async (event) => {
                              // Get the selected value
                              const selectedValue = event.target.value;
                              const controlRef = selectedValue ? doc(db, 'PositionControls', selectedValue) : null;
                              const controlSnap = selectedValue ? await getDoc(controlRef) : null;
                              const oSelectedControl = controlSnap.data() || {}
                              setSelectedControl(oSelectedControl)
                              selectedControl
                              if (selectedValue) {
                                props.setFieldValue('control', selectedValue)
                                props.setFieldValue('tradeCategory', oSelectedControl.tradeCategory || '')
                                props.setFieldValue('transaction', oSelectedControl.transaction || '')
                                props.setFieldValue('optionType', oSelectedControl.optionType || '')
                              }
                            }}
                          >
                            {myStrategies.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>

                        {/* Trade Category Field */}
                        <Grid item xs={12} md={6} lg={6}>
                          <Field
                            as={TextField}
                            name="tradeCategory"
                            label="Trade Category"
                            type="text"
                            fullWidth
                            error={props.errors.tradeCategory && props.touched.tradeCategory}
                            helperText={<ErrorMessage name="tradeCategory" />}
                            disabled // This field is disabled
                            value={props.values.tradeCategory} // Value is set dynamically
                            style={fieldStyle}
                          />
                        </Grid>

                        {/* Transaction Field */}
                        <Grid item xs={12} md={6} lg={6}>
                          <Field
                            as={TextField}
                            name="transaction"
                            label="Transaction"
                            type="text"
                            fullWidth
                            error={props.errors.transaction && props.touched.transaction}
                            helperText={<ErrorMessage name="transaction" />}
                            disabled // This field is also disabled
                            value={props.values.transaction} // Value is set dynamically
                            style={fieldStyle}
                          />
                        </Grid>

                        {/* Option Type Field */}
                        <Grid item xs={12} md={6} lg={6}>
                          <Field
                            as={TextField}
                            name="optionType"
                            label="Option Type"
                            type="text"
                            fullWidth
                            error={props.errors.optionType && props.touched.optionType}
                            helperText={<ErrorMessage name="optionType" />}
                            disabled // This field is also disabled
                            value={props.values.optionType} // Value is set dynamically
                            style={fieldStyle}
                          />
                        </Grid>


                        {/* Market Field */}
                        <Grid item xs={12}>
                          <Field
                            as={TextField}
                            id="outlined-select-market"
                            select
                            label="Market"
                            required
                            fullWidth
                            style={fieldStyle}
                            name="market"
                            error={props.errors.market && props.touched.market}
                          >
                            {MarketTypes.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                      </>
                    }
                    {(props.values.engineType === "AT") &&
                      <>
                        <Grid item xs={12}>
                          <Field
                            as={TextField}
                            id="outlined-select-strategy"
                            select
                            label="Select Strategy"
                            helperText="Please select your strategy to be linked"
                            // required
                            style={fieldStyle}
                            name="strategy"
                            error={props.errors.strategy && props.touched.strategy}
                            onChange={async (event) => {
                              // Get the selected value
                              const selectedValue = event.target.value;
                              const controlRef = selectedValue ? doc(db, 'Strategies', selectedValue) : null;
                              const controlSnap = selectedValue ? await getDoc(controlRef) : null;
                              const oSelectedStat = controlSnap.data() || {}
                              setSelectedStrategy(oSelectedStat)
                              selectedStrategy
                              if (selectedValue) {
                                props.setFieldValue('strategy', selectedValue)
                                props.setFieldValue('tradeCategory', oSelectedStat.tradeCategory || '')
                                props.setFieldValue('transaction', oSelectedStat.transaction || '')
                                props.setFieldValue('optionType', oSelectedStat.optionType || '')
                                props.setFieldValue('market', oSelectedStat.market || '')

                              }
                            }}
                          >
                            {myAlgos.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>

                        {/* Trade Category Field */}
                        <Grid item xs={12} md={6} lg={6}>
                          <Field
                            as={TextField}
                            name="tradeCategory"
                            label="Trade Category"
                            type="text"
                            fullWidth
                            error={props.errors.tradeCategory && props.touched.tradeCategory}
                            helperText={<ErrorMessage name="tradeCategory" />}
                            disabled // This field is disabled
                            value={props.values.tradeCategory} // Value is set dynamically
                            style={fieldStyle}
                          />
                        </Grid>

                        {/* Transaction Field */}
                        <Grid item xs={12} md={6} lg={6}>
                          <Field
                            as={TextField}
                            name="transaction"
                            label="Transaction"
                            type="text"
                            fullWidth
                            error={props.errors.transaction && props.touched.transaction}
                            helperText={<ErrorMessage name="transaction" />}
                            disabled // This field is also disabled
                            value={props.values.transaction} // Value is set dynamically
                            style={fieldStyle}
                          />
                        </Grid>

                        {/* Option Type Field */}
                        <Grid item xs={12} md={6} lg={6}>
                          <Field
                            as={TextField}
                            name="optionType"
                            label="Option Type"
                            type="text"
                            fullWidth
                            error={props.errors.optionType && props.touched.optionType}
                            helperText={<ErrorMessage name="optionType" />}
                            disabled // This field is also disabled
                            value={props.values.optionType} // Value is set dynamically
                            style={fieldStyle}
                          />
                        </Grid>


                        {/* Market Field */}
                        <Grid item xs={12}>
                          <Field
                            as={TextField}
                            label="Market"
                            required
                            fullWidth
                            type="text"
                            style={fieldStyle}
                            name="market"
                            value={props.values.market} // Value is set dynamically
                            disabled
                            error={props.errors.market && props.touched.market}
                          >
                          </Field>
                        </Grid>


                        {(props.values.optionType === "Both") &&
                          <>
                            <Grid item xs={12}>
                              <Field
                                as={TextField}
                                id="outlined-select-statControlCE"
                                select
                                label="Control CE"
                                helperText="Please select your control CE"
                                // required
                                style={fieldStyle}
                                name="statControlCE"
                                onChange={async (event) => {
                                  // Get the selected value
                                  const selectedValue = event.target.value;
                                  const controlRef = selectedValue ? doc(db, 'PositionControls', selectedValue) : null;
                                  const controlSnap = selectedValue ? await getDoc(controlRef) : null;
                                  const oSelectedControl = controlSnap.data() || {}
                                  setSelectedControlCE(oSelectedControl)
                                  selectedControlCE
                                  if (selectedValue) {
                                    props.setFieldValue('statControlCE', selectedValue)
                                  }
                                }}
                                error={props.errors.statControlCE && props.touched.statControlCE}
                              >
                                {myStrategies.map((option) => (
                                  <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Field>
                            </Grid>

                            <Grid item xs={12}>
                              <Field
                                as={TextField}
                                id="outlined-select-statControlPE"
                                select
                                label="Control PE"
                                helperText="Please select your control PE"
                                // required
                                style={fieldStyle}
                                name="statControlPE"
                                onChange={async (event) => {
                                  // Get the selected value
                                  const selectedValue = event.target.value;
                                  const controlRef = selectedValue ? doc(db, 'PositionControls', selectedValue) : null;
                                  const controlSnap = selectedValue ? await getDoc(controlRef) : null;
                                  const oSelectedControl = controlSnap.data() || {}
                                  setSelectedControlPE(oSelectedControl)
                                  selectedControlPE
                                  if (selectedValue) {
                                    props.setFieldValue('statControlPE', selectedValue)
                                  }
                                }}
                                error={props.errors.statControlPE && props.touched.statControlPE}
                              >
                                {myStrategies.map((option) => (
                                  <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Field>
                            </Grid>
                          </>
                        }

                        {(props.values.optionType === "CE") &&
                          <>
                            <Grid item xs={12}>
                              <Field
                                as={TextField}
                                id="outlined-select-statControlCE"
                                select
                                label="Control CE"
                                helperText="Please select your control CE"
                                // required
                                style={fieldStyle}
                                name="statControlCE"
                                onChange={async (event) => {
                                  // Get the selected value
                                  const selectedValue = event.target.value;
                                  const controlRef = selectedValue ? doc(db, 'PositionControls', selectedValue) : null;
                                  const controlSnap = selectedValue ? await getDoc(controlRef) : null;
                                  const oSelectedControl = controlSnap.data() || {}
                                  setSelectedControlCE(oSelectedControl)
                                  selectedControlCE
                                  if (selectedValue) {
                                    props.setFieldValue('statControlCE', selectedValue)
                                  }
                                }}
                                error={props.errors.statControlCE && props.touched.statControlCE}
                              >
                                {myStrategies.map((option) => (
                                  <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Field>
                            </Grid>
                          </>
                        }

                        {(props.values.optionType === "PE") &&
                          <>
                            <Grid item xs={12}>
                              <Field
                                as={TextField}
                                id="outlined-select-statControlPE"
                                select
                                label="Control PE"
                                helperText="Please select your control PE"
                                // required
                                style={fieldStyle}
                                name="statControlPE"
                                onChange={async (event) => {
                                  // Get the selected value
                                  const selectedValue = event.target.value;
                                  const controlRef = selectedValue ? doc(db, 'PositionControls', selectedValue) : null;
                                  const controlSnap = selectedValue ? await getDoc(controlRef) : null;
                                  const oSelectedControl = controlSnap.data() || {}
                                  setSelectedControlPE(oSelectedControl)
                                  selectedControlPE
                                  if (selectedValue) {
                                    props.setFieldValue('statControlPE', selectedValue)
                                  }
                                }}
                                error={props.errors.statControlPE && props.touched.statControlPE}
                              >
                                {myStrategies.map((option) => (
                                  <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Field>
                            </Grid>
                          </>
                        }
                      </>
                    }

                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        id="outlined-select-exchange"
                        select
                        label="Select Exchange"
                        helperText="Please select your exchange linked"
                        // required
                        style={fieldStyle}
                        name="exchange"
                        error={props.errors.exchange && props.touched.exchange}
                      >
                        {myExchanges.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="enablePaperTrade">
                        {({ field, form }) => (
                          <FormControlLabel
                            control={<Switch
                              defaultChecked={props.values.enablePaperTrade}
                              checked={field.value}  // Still controlled by Formik
                              onChange={(event) => {
                                form.setFieldValue(field.name, event.target.checked);
                              }}
                            />}
                            label="Enable Paper Trading"
                          />
                        )}
                      </Field>
                      <ErrorMessage name="enablePaperTrade" component="div" />
                      {props.values.enablePaperTrade &&
                        <>
                          <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
                            Live trading is off
                          </Typography>
                        </>
                      }
                      {!props.values.enablePaperTrade &&
                        <>
                          <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
                            Live trading is On
                          </Typography>
                        </>
                      }
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button type="submit" variant="contained" color="primary" startIcon={<Iconify icon="material-symbols:save" />}>
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
}

export default CreateTradeEngineForm;
{/* <Grid item xs={12}>
                      <Field
                        as={TextField}
                        id="outlined-select-tradetype"
                        select
                        label="Trade Type"
                        helperText={<ErrorMessage name="tradetype" />}
                        required
                        value={props.values.tradetype}
                        style={fieldStyle}
                        name="tradetype"
                        error={props.errors.tradetype&&props.touched.tradetype}
                        fullWidth
                      >
                        {tradetypes.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        id="outlined-select-strategy"
                        select
                        label="Algo"
                        helperText={<ErrorMessage name="strategy" />}
                        required
                        value={props.values.strategy}
                        style={fieldStyle}
                        name="strategy"
                        error={props.errors.strategy&&props.touched.strategy}
                        fullWidth
                      >
                        {strategy.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>  
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        id="outlined-select-coin"
                        select
                        label="Coin"
                        helperText={<ErrorMessage name="coin" />}
                        required
                        value={props.values.coin}
                        style={fieldStyle}
                        name="coin"
                        error={props.errors.coin&&props.touched.coin}
                        fullWidth
                      >
                        {coin.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>                     */}