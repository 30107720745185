// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
const navConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: icon('ic-dashboard-fill'),
  },
  {
    title: 'Exchanges/Brokers',
    path: '/dashboard/exchanges',
    icon: icon('icon-park-exchange'),
  },
  {
    title: 'Position Control Plans',
    path: '/dashboard/mystrategies',
    icon: icon('simple-icons-microstrategy'),
  },
  {
    title: 'Strategies',
    path: '/dashboard/myalgos',
    icon: icon('token-algo'),
  },
  {
    title: 'Trade Engines',
    path: '/dashboard/myengines',
    icon: icon('flat-color-icons-eng'),
  }
];

export default navConfig;
